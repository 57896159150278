export const skills = [
  {
    name: "React",
    category: ["developer"],
    type: "language",
    primary: true,
  },
  {
    name: "Next.js",
    category: ["developer"],
    type: "language",
    primary: true,
  },
  {
    name: "AngularJS",
    category: ["developer"],
    type: "language",
    primary: true,
  },
  {
    name: "Typescript",
    category: ["developer"],
    type: "language",
    primary: true,
  },
  {
    name: "JavaScript",
    category: ["developer", "artist"],
    type: "language",
    primary: false,
  },
  {
    name: "Node",
    category: ["developer"],
    type: "language",
    primary: true,
  },
  {
    name: "Express",
    category: ["developer"],
    type: "language",
    primary: false,
  },
  {
    name: "jQuery",
    category: ["developer"],
    type: "language",
    primary: false,
  },
  {
    name: "HTML",
    category: ["developer", "artist"],
    type: "language",
    primary: false,
  },
  {
    name: "AWS",
    category: ["developer"],
    type: "tool",
    primary: true,
  },
  {
    name: "Heroku",
    category: ["developer"],
    type: "tool",
    primary: true,
  },
  {
    name: "Netlify",
    category: ["developer"],
    type: "tool",
    primary: true,
  },
  {
    name: "Tailwind CSS",
    category: ["developer"],
    type: "language",
    primary: true,
  },
  { name: "SASS", category: ["developer"], type: "language", primary: true },
  {
    name: "LESS",
    category: ["developer"],
    type: "language",
    primary: false,
  },
  {
    name: "CSS",
    category: ["developer", "artist"],
    type: "language",
    primary: false,
  },
  {
    name: "C#",
    category: ["developer"],
    type: "language",
    primary: false,
  },
  {
    name: "MongoDB",
    category: ["developer"],
    type: "language",
    primary: true,
  },
  {
    name: "SQL",
    category: ["developer"],
    type: "language",
    primary: false,
  },
  {
    name: "NoSQL",
    category: ["developer"],
    type: "language",
    primary: false,
  },
  { name: "MySQL", category: ["developer"], type: "language", primary: true },
  {
    name: "MongoDB Compass",
    category: ["developer"],
    type: "tool",
    primary: false,
  },
  {
    name: "MongoDB Atlas",
    category: ["developer"],
    type: "tool",
    primary: false,
  },
  {
    name: "Mongoose",
    category: ["developer"],
    type: "language",
    primary: false,
  },
  {
    name: "Mongo Shell",
    category: ["developer"],
    type: "tool",
    primary: false,
  },
  /* {
    name: "MySQL Workbench",
    category: ["developer"],
    type: "tool",
    primary: false,
  }, */
  {
    name: "Postman",
    category: ["developer"],
    type: "tool",
    primary: true,
  },
  {
    name: "Git",
    category: ["developer"],
    type: "tool",
    primary: true,
  },
  {
    name: "GitHub",
    category: ["developer"],
    type: "tool",
    primary: false,
  },
  {
    name: "NPM",
    category: ["developer"],
    type: "tool",
    primary: false,
  },
  {
    name: "Squarespace",
    category: ["developer", "writer"],
    type: "tool",
    primary: true,
  },
  {
    name: "Adobe Creative Cloud",
    category: ["artist"],
    type: "tool",
    primary: true,
  },
  {
    name: "Photoshop",
    category: ["artist"],
    type: "tool",
    primary: true,
  },
  {
    name: "Illustrator",
    category: ["artist"],
    type: "tool",
    primary: true,
  },
  {
    name: "InDesign",
    category: ["artist", "writer"],
    type: "tool",
    primary: true,
  },
  {
    name: "Acrobat",
    category: ["writer", "general"],
    type: "tool",
    primary: false,
  },
  {
    name: "Adobe Bridge",
    category: ["artist", "general"],
    type: "tool",
    primary: false,
  },
  {
    name: "Figma",
    category: ["developer", "artist"],
    type: "tool",
    primary: false,
  },
  {
    name: "FontMaker",
    category: ["developer", "artist", "general"],
    type: "tool",
    primary: false,
  },
  {
    name: "Microsoft Word",
    category: ["general", "writer"],
    type: "tool",
    primary: false,
  },
  {
    name: "Microsoft Excel",
    category: ["general"],
    type: "tool",
    primary: false,
  },
  { name: "PowerPoint", category: ["general"], type: "tool", primary: false },
  {
    name: "Microsoft Outlook",
    category: ["general"],
    type: "tool",
    primary: false,
  },
  {
    name: "Microsoft Teams",
    category: ["general"],
    type: "tool",
    primary: false,
  },
  {
    name: "Visual Studio Code",
    category: ["developer"],
    type: "tool",
    primary: false,
  },
  {
    name: "IntelliJ",
    category: ["developer"],
    type: "tool",
    primary: false,
  },
  {
    name: "Unity",
    category: ["developer"],
    type: "tool",
    primary: false,
  },
  {
    name: "Autodesk Maya",
    category: ["artist"],
    type: "tool",
    primary: false,
  },
  {
    name: "ZBrush",
    category: ["artist"],
    type: "tool",
    primary: true,
  },
  {
    name: "Adobe Substance Painter",
    category: ["artist"],
    type: "",
    primary: false,
  },
  {
    name: "MERN",
    category: ["developer"],
    type: "proficiency",
    primary: false,
  },
  {
    name: "Version Control",
    category: ["developer"],
    type: "proficiency",
    primary: false,
  },
  {
    name: "REST",
    category: ["developer"],
    type: "proficiency",
    primary: false,
  },
  {
    name: "RESTful",
    category: ["developer"],
    type: "proficiency",
    primary: false,
  },
  {
    name: "API Design/Development",
    category: ["developer"],
    type: "proficiency",
    primary: false,
  },
  {
    name: "Continuous Integration/Continuous Development (CI/CD)",
    category: ["developer"],
    type: "proficiency",
    primary: false,
  },
  {
    name: "Agile",
    category: ["developer"],
    type: "proficiency",
    primary: false,
  },
  {
    name: "DevOps",
    category: ["developer"],
    type: "proficiency",
    primary: false,
  },
  {
    name: "Relational Database Design/Development",
    category: ["developer"],
    type: "proficiency",
    primary: false,
  },
  {
    name: "User Interface Design",
    category: ["developer"],
    type: "proficiency",
    primary: false,
  },
  {
    name: "User Experience Design",
    category: ["developer"],
    type: "proficiency",
    primary: false,
  },
  {
    name: "Graphic Design",
    category: ["artist"],
    type: "proficiency",
    primary: false,
  },
  {
    name: "3D Modeling",
    category: ["artist"],
    type: "proficiency",
    primary: false,
  },
  {
    name: "Digital Illustration",
    category: ["artist"],
    type: "proficiency",
    primary: false,
  },
  {
    name: "Windows",
    category: ["developer", "general"],
    type: "operating system",
    primary: false,
  },
  {
    name: "Mac",
    category: ["developer", "general"],
    type: "operating system",
    primary: false,
  },
  {
    name: "Linux",
    category: ["developer"],
    type: "operating system",
    primary: false,
  },
];
