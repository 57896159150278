function debounce(fn, ms = 1000) {
  // console.log("Running debounce");
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, ms);
  };
}

const states = new Map([
  ["Alabama", "AL"],
  ["Alaska", "AK"],
  ["Arizona", "AR"],
  ["Arkansas", "AZ"],
  ["American Samoa", "AS"],
  ["California", "CA"],
  ["Colorado", "CO"],
  ["Connecticut", "CT"],
  ["Delaware", "DE"],
  ["District of Columbia", "DC"],
  ["Florida", "FL"],
  ["Georgia", "GA"],
  ["Guam", "GU"],
  ["Hawaii", "HI"],
  ["Illinois", "IL"],
  ["Indiana", "IN"],
  ["Iowa", "IA"],
  ["Kansas", "KS"],
  ["Kentucky", "KY"],
  ["Louisiana", "LA"],
  ["Maine", "ME"],
  ["Maryland", "MD"],
  ["Massachusetts", "MA"],
  ["Michigan", "MI"],
  ["Minnesota", "MN"],
  ["Mississippi", "MS"],
  ["Missouri", "MO"],
  ["Montana", "MT"],
  ["Nebraska", "NE"],
  ["Nevada", "NV"],
  ["New Hampshire", "NH"],
  ["New Jersey", "NJ"],
  ["New Mexico", "NM"],
  ["New York", "NY"],
  ["North Carolina", "NC"],
  ["North Dakota", "ND"],
  ["Northern Mariana Islands", "CM"],
  ["Ohio", "OH"],
  ["Oklahoma", "OK"],
  ["Oregon", "OR"],
  ["Pennsylvania", "PA"],
  ["Puerto Rico", "PR"],
  ["Rhode Island", "RI"],
  ["South Carolina", "SC"],
  ["Tennessee", "TN"],
  ["Texas", "TX"],
  ["Trust Territories", "TT"],
  ["Utah", "UT"],
  ["Vermont", "VT"],
  ["Virginia", "VA"],
  ["Virgin Islands", "VI"],
  ["Washington", "WA"],
  ["West Virginia", "WV"],
  ["Wisconsin", "WI"],
  ["Wyoming", "WY"],
]);

const _states = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AR",
  Arkansas: "AZ",
  "American Samoa": "AS",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  "District of Columbia": "DC",
  Florida: "FL",
  Georgia: "GA",
  Guam: "GU",
  Hawaii: "HI",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Northern Mariana Islands": "CM",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Puerto Rico": "PR",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  Tennessee: "TN",
  Texas: "TX",
  "Trust Territories": "TT",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  "Virgin Islands": "VI",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};

const skills = [
  {
    name: "HTML",
    type: "computer language",
    subType: "markup",
    group: "",
    abbr: "html",
  },
  {
    name: "CSS",
    type: "computer language",
    subType: "style sheet",
    group: "",
    abbr: "css",
  },
  {
    name: "JavaScript",
    type: "computer language",
    subType: "scripting language",
    group: "",
    abbr: "js",
  },
  {
    name: "Typescript",
    type: "superset library",
    subType: "",
    group: "JavaScript",
    abbr: "ts",
  },
  {
    name: "Next.js",
    type: "framework",
    subType: "",
    group: "JavaScript",
    abbr: "",
  },
  {
    name: "Node",
    type: "runtime environment",
    subType: "",
    group: "JavaScript",
    abbr: "",
  },
  {
    name: "Express",
    type: "framework",
    subType: "",
    group: "Node",
    abbr: "",
  },
  {
    name: "React",
    type: "library",
    subType: "",
    group: "JavaScript",
    abbr: "",
  },
  {
    name: "C#",
    type: "computer language",
    subType: "programming",
    abbr: "",
  },
  {
    name: "MongoDB",
    type: "database program",
    subType: "programming",
    group: "NoSQL",
    abbr: "",
  },
  {
    name: "AWS",
    type: "database program",
    subType: "programming",
    group: "NoSQL",
    abbr: "",
  },
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const abbr = (month) => {
  const int = months.indexOf(month) + 1;
  return int.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

const iconMap = {
  HTML: "H",
  CSS: "c",
  JavaScript: "J",
  Node: "D",
  Express: "",
  React: "R",
  AngularJS: "A",
  Angular: "A",
  Typescript: "j",
  "Next.js": "ã",
  "C#": "Y",
  MongoDB: "M",
  Netlify: "ï",
  NoSQL: "",
  MySQL: "F",
  SQL: "",
  "MongoDB Compass": "M",
  "MongoDB Atlas": "M",
  Mongoose: "",
  "Mongo Shell": "M",
  "MySQL Workbench": "F",
  Postman: "I",
  Git: "g",
  GitHub: "K",
  Heroku: "ä",
  Squarespace: "s",
  "Adobe Creative Cloud": "u",
  "Creative Cloud": "u",
  "Adobe Photoshop": "-",
  Photoshop: "-",
  "Adobe Illustrator": "=",
  Illustrator: "=",
  "Adobe InDesign": "_",
  InDesign: "_",
  "Adobe Acrobat": ":",
  Acrobat: ":",
  Bridge: "",
  "Adobe XD": "+",
  XD: "+",
  Figma: "e",
  "Microsoft Word": "",
  "Microsoft Excel": "",
  PowerPoint: "",
  "Microsoft Outlook": "",
  "Microsoft Teams": "",
  "Visual Studio": "[",
  "Visual Studio Code": "]",
  "Autodesk Maya": "",
  ZBrush: "Z",
  "Adobe Substance Painter": "",
  "Version Control": "g",
  PC: "y",
  Windows: "y",
  "Windows 10": "y",
  "Windows 11": "y",
  Mac: "a",
  MacOS: "a",
  "MacOS X+": "a",
  Linux: "<",
  CSS3: "C",
  LESS: "L",
  SASS: "E",
  ES6: "J",
  jQuery: "q",
  NPM: "N",
  Unity: "U",
  Python: "|",
  "Tailwind CSS": "ì",
  AWS: "ð",
};

export { states, skills, debounce, iconMap, abbr };
